import * as PIXI from "pixi.js";
import rainUrl from "url:./output.mp4";

const BRUSH_SIZE = 20;
const BLUR_SIZE = 80;

const app = new PIXI.Application({ resizeTo: window });

document.body.appendChild(app.view);

// prepare circle texture, that will be our brush
const brush = new PIXI.Graphics()
  .beginFill(0xffffff)
  .drawCircle(0, 0, BRUSH_SIZE);

// Create a line that will interpolate the drawn points
const line = new PIXI.Graphics();

async function setup() {
  const { width, height } = app.screen;
  const stageSize = { width, height };

  console.log("loading…");
  const rainTexture = await PIXI.Assets.load(rainUrl);
  console.log("loaded");
  // const rainTexture = PIXI.Texture.from(rainUrl);
  const revealVideoSprite = new PIXI.Sprite(rainTexture);
  revealVideoSprite.width = app.screen.width;
  revealVideoSprite.height = app.screen.height;

  const backgroundVideoSprite = new PIXI.Sprite(rainTexture);
  backgroundVideoSprite.width = app.screen.width;
  backgroundVideoSprite.height = app.screen.height;

  const videoToReveal = Object.assign(revealVideoSprite, stageSize);
  const backgroundVideo = Object.assign(backgroundVideoSprite, stageSize);

  const blurFilter = new PIXI.BlurFilter(BLUR_SIZE);

  const renderTexture = PIXI.RenderTexture.create(stageSize);
  const renderTextureSprite = new PIXI.Sprite(renderTexture);
  videoToReveal.mask = renderTextureSprite;

  app.stage.addChild(backgroundVideo, videoToReveal, renderTextureSprite);

  app.stage.eventMode = "static";
  // const hitArea = new PIXI.RoundedRectangle(0, 0, 1000, 1500, 50);
  // app.stage.hitArea = hitArea;
  setTimeout(() => {
    backgroundVideo.filters = [blurFilter];
    app.stage
      .on("pointerdown", pointerDown)
      .on("pointerup", pointerUp)
      .on("pointerupoutside", pointerUp)
      .on("pointermove", pointerMove);
  }, 2500);

  let dragging = false;
  let lastDrawnPoint = null;

  function pointerMove({ global: { x, y } }) {
    if (dragging) {
      brush.position.set(x, y);
      app.renderer.render(brush, {
        renderTexture,
        clear: false,
        skipUpdateTransform: false,
      });
      // Smooth out the drawing a little bit to make it look nicer
      // this connects the previous drawn point to the current one
      // using a line
      if (lastDrawnPoint) {
        line
          .clear()
          .lineStyle({ width: 2 * BRUSH_SIZE, color: 0xffffff })
          .moveTo(lastDrawnPoint.x, lastDrawnPoint.y)
          .lineTo(x, y);
        app.renderer.render(line, {
          renderTexture,
          clear: false,
          skipUpdateTransform: false,
        });
      }
      lastDrawnPoint = lastDrawnPoint || new PIXI.Point();
      lastDrawnPoint.set(x, y);
    }
  }

  function pointerDown(event) {
    dragging = true;
    pointerMove(event);
  }

  function pointerUp(event) {
    dragging = false;
    lastDrawnPoint = null;
  }
}

setup();
